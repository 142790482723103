import header from "./modules/header";
import txtAnim from "./modules/txtAnim";
import slider from "./modules/slider";
import anchors from "./modules/anchors";
import anim from "./modules/anim";
import scroll from "./modules/scroll";
import backBtn from "./modules/backBtn";

document.addEventListener('DOMContentLoaded', ()=> {
    header();
    txtAnim();
    slider();
    anchors();
    anim();
    scroll();
    backBtn();
});