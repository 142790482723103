function slider () {
    /**
     * Слайдер с категориями на главной
     */
    const categorySlider = document.querySelector('.home__categories-slider');

    if(categorySlider) {
        let slider = new Swiper(categorySlider, {
            slidesPerView: 'auto',
            spaceBetween: 20,
            freeMode: true,
        });
    }


    /**
     * Слайдер с отзывами
     */
    const reviewSlider = document.querySelector('.home__reviews-slider');

    if(reviewSlider) {
        let slider = new Swiper(reviewSlider, {
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 600,
            grabCursor: true,
            loop: true,
            // effect: 'fade',
            navigation: {
                nextEl: ".home__reviews-slider-btn--next",
                prevEl: ".home__reviews-slider-btn--prev",
            },
        });
    }

    
    /**
     * Слайдер с 
     */
    const upsellProducts = document.querySelector('.product__upsell-slider');

    if(upsellProducts) {
        let slider = new Swiper(upsellProducts, {
            slidesPerView: 3,
            spaceBetween: 30,
            speed: 600,
            grabCursor: true,
            loop: true,
            autoplay: {
                delay: 3000,
            },
            pagination: {
                el: ".product__upsell-pagination",
                clickable: true,
            },
            breakpoints: {
                1: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1261: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                }
            }
        });
    }
}

export default slider;