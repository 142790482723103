function header () {
    const header = document.querySelector('.header');
    const nav = document.querySelector('.header__nav');
    const navLinks = document.querySelectorAll('.header__nav-link');
    const burger = document.querySelector('.header__burger');

    burger.addEventListener('click', ()=> {
        burger.classList.toggle('active');
        nav.classList.toggle('show');

        if(!nav.classList.contains('show')) {
            navLinks.forEach(el=> el.classList.remove('anim'));
        } else {
            navLinks.forEach((el)=> {
                const time = el.dataset.time;

                setTimeout(()=> {
                    el.classList.add('anim')
                }, time);
            });
        }
    });

    if(window.innerWidth <= 767) {
        document.addEventListener('scroll', ()=> {
            window.scrollY >= 50 ? header.classList.add('painted') : header.classList.remove('painted'); 
        });
    }
}

export default header;