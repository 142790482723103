function backBtn () {
    const backBtn = document.querySelector('.product__back');

    if(backBtn) {
        backBtn.addEventListener('click', (e)=> {
            e.preventDefault();
            history.back();
        });
    }
}

export default backBtn;