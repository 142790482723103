function txtAnim () {
    const animSymbolsWrapper = document.querySelector('.anim-symbols-wrapper');
    
    if(animSymbolsWrapper) {
        const animSymbolsTxt = document.querySelector('.anim-symbols-txt');
        const animSymbolsArr = animSymbolsTxt.textContent.split('');
        
        animSymbolsTxt.innerHTML = animSymbolsArr.map((symbol) => `<span style="opacity: 0.1">${symbol}</span>`).join('');
        
        const symbols = animSymbolsTxt.querySelectorAll('span');
        
        function updateOpacity() {
            const rect = animSymbolsWrapper.getBoundingClientRect();
            const viewHeight = window.innerHeight / 1.15;
        
            symbols.forEach((symbol) => {
                const symbolRect = symbol.getBoundingClientRect();
        
                //Calculate the distance of the symbol from the bottom of the viewable area
                const distanceFromBottom = viewHeight - (symbolRect.top + symbolRect.height);
        
                //Calculate the opacity based on the distance
                const opacity = Math.max(0, Math.min(1, 2 * distanceFromBottom / viewHeight));
        
                //Apply the opacity to the symbol
                symbol.style.opacity = opacity.toFixed(2);
            });
        }
        
        function handleScroll() {
            requestAnimationFrame(updateOpacity);
        }
        
        //Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);
        
        // Call the updateOpacity function initially to set the opacity for the visible symbols
        updateOpacity();
    }
    
}

export default txtAnim;