function scroll () {
    /**
     * Настройка кнопки фильтра на странице каталога
     */
    const productsFilter = document.querySelector('.catalog__filter');

    if(productsFilter) {
        const filterBtn = document.querySelector('.catalog__filter-btn');
        const categoriesList = document.querySelector('.catalog__filter-wrapper');
        const contact = document.querySelector('.contact');

        productsFilter.classList.add('show')

        //Настройка отображения фильтра
        document.addEventListener('scroll', ()=> {
            const contactOffset = contact.offsetTop - contact.offsetHeight;

            if(window.scrollY <= contactOffset) {
                productsFilter.classList.add('show');
            } else {
                productsFilter.classList.remove('show');
                categoriesList.classList.remove('show');
            }
        });

        //Настройка отображения списка категорий
        filterBtn.addEventListener('click', ()=> {
            categoriesList.classList.toggle('show');
        });
    }


    /**
     * Настройка заказать на странице товара
     */

    const byProductBtn = document.querySelector('.product__by');

    if(byProductBtn) {
        //Показываем кнопку при загрузки страницы
        byProductBtn.classList.add('show');

        //Настройка отображения кнопки при скролле
        const contact = document.querySelector('.contact');

        document.addEventListener('scroll', ()=> {
            const contactOffset = contact.offsetTop - contact.offsetHeight;

            if(window.scrollY >= contactOffset) {
                byProductBtn.classList.remove('show');
            } else {
                byProductBtn.classList.add('show');
            }
        });
    }
}

export default scroll;